/**
 * toggleActiveElement
 * Toggles "active" class on one item between many of the same
 * (like one li of multiples li's in an ul)
 *
 * @param {object} element Target DOM Node
 */
const toggleActiveElement = (element) => {
  element.parentNode.childNodes.forEach((child) => {
    if (child instanceof HTMLElement) {
      child.classList.remove('active')
    }
  })

  element.classList.add('active')
}

jQuery(() => {
  // Start default product carosuel(s)
  jQuery('.category-products--carousel ul').owlCarousel({
    itemsCustom: [[0, 1], [768, 2], [1025, 4]],
    lazyLoad: true,
    navigation: true,
    scrollPerPage: true,
    theme: false,
    navigationText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>']
  })

  // Toggle currently visible tab
  document.querySelectorAll('.tabs label').forEach((tab) => {
    tab.addEventListener('click', function () {
      const target = this.dataset.target
      toggleActiveElement(this)
      toggleActiveElement(document.getElementById(target))
    })
  })
})
